import React from "react";
import { Container, Stack, Typography, Box, IconButton } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "./assets/imgs/logo.jpg";
import underline from "./assets/imgs/underline.jpg";
import sous_logo from "./assets/imgs/oeuvre_sous_titre.jpg";

const UnderConstructionPage = () => {
    return (
        <Container maxWidth="lg" style={{ textAlign: "center", padding: "2rem" }}>
            <Stack spacing={2} alignItems={"center"} marginBottom={"35px"}>
                <Box
                    component="img"
                    src={logo}
                    alt="Logo"
                    sx={{
                        maxWidth: { xs: "100%", sm: "550px" },
                        width: "100%",
                        marginBottom: "1rem",
                    }}
                />
                <Box
                    component="img"
                    src={underline}
                    alt="underline"
                    sx={{
                        maxWidth: { xs: "100%", sm: "500px" },
                        width: "100%",
                        marginBottom: "1rem",
                    }}
                />
                <Box
                    component="img"
                    src={sous_logo}
                    alt="sous_logo"
                    sx={{
                        maxWidth: { xs: "100%", sm: "500px" },
                        width: "100%",
                        marginBottom: "2rem",
                    }}
                />
            </Stack>

            <Typography variant="h4" fontSize="2rem" gutterBottom>
                Paniers - Jardins & Paysages - Installations - Transmission des savoirs
                faire.
            </Typography>
            <Typography variant="h4" fontSize="2rem" gutterBottom>
                Basketry - Landscape design - Artwork installation - Masterclasses.
            </Typography>
            <Box my={4}>
                <PriorityHighIcon
                    fontSize="large"
                    style={{ fontSize: "80px", marginBottom: "15px" }}
                />
                <Typography variant="h5" color="red">
                    Pour l’été, le site est en construction, veuillez nous en excuser et
                    merci pour votre compréhension.
                </Typography>
                <br />
                <Typography variant="h5" color="red">
                    The site is under construction during summer. We apologize for the
                    inconvenience and thank you for your understanding.
                </Typography>
            </Box>
            <Stack
                mt={4}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
            >
                <IconButton
                    className="insta-logo"
                    aria-label="Instagram"
                    href="https://instagram.com/celuiquitresse"
                    style={{
                        textDecoration: "none",
                        color: "black",
                        fontSize: "2rem",
                    }}
                >
                    <InstagramIcon style={{ fontSize: "3rem" }} />
                </IconButton>
                <a
                    className="insta-link"
                    href="https://instagram.com/celuiquitresse"
                    style={{
                        textDecoration: "none",
                        color: "inherit",
                        fontSize: "2rem",
                    }}
                >
                    @celuiquitresse
                </a>
            </Stack>
        </Container>
    );
};

export default UnderConstructionPage;
